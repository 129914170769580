<template>
  <div id="component">
    <div class="module1"><span class="VerticalLine"></span><span class="content">报名中 的培训</span>
      <el-divider></el-divider>
      <div class="module-content">
        当前暂无 报名中 培训
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "TrainingPlan",
  data(){
    return{
      radio1:''
    }
  }
}
</script>

<style scoped>
#component{
  margin-top: 20px;
}
.module1,.module2{
  margin-top: 60px;

}
.module1{
  color: #67C23A;
}

.module-content{
  margin-top: 10px;
  text-align: center;
}

.content {
  display: inline-block;
  vertical-align: top;
}

.VerticalLine {
  display: inline-block;
  width: 3px;
  height: 20px;
  border-radius: 5px;
  margin-right: 10px;
  vertical-align: center;
}
.module1 .VerticalLine{
  background-color: #67C23A;
}

.module-content li{
  position: relative;
  display: inline-block;
  width: 14%;
  height: 250px;
  margin-right: 2%;
  vertical-align: top;
  overflow: hidden;

}
.module-content li img{
  width: 100%;
  height: 100%;
}
.module-content li :hover{
  transform: scale(1.1);
}
.curtain{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  padding: 20px;
  color: #fff;
  cursor: pointer;
  z-index: 99;
  transition: all 0.6s;
}
.curtain .label{
  text-align: left;
  margin-top: 20px;
}
.curtain .el-tag{
  margin-top: 60px;
  background-color: #E6A23C;
  color: #fff;
}

.el-divider {
  margin: 0;
}
/deep/.el-radio-button__inner{
  width: 100px;
  margin-right: 20px;
  border-radius: 0px !important;
  border: 1px #E9E9EB solid;
}

</style>