<template>
  <div id="main">
    <img src="../../images/正在建设中.png" alt="">
    <h2>页面正在建设中...</h2>
  </div>
</template>

<script>
export default {
  name: "StandardizationConsultation"
}
</script>

<style scoped>
#main {
  width: 1200px;
  margin: 110px auto 0 auto;
  text-align: center;
}
img{
  width: 600px;
}
h2{
  font-family: '宋体';
  color: #99a9bf;
}


</style>