<template>
  <div id="main">
    <h2>①服务内容</h2>
    <p class="content">
      针对用能单位提供各具技术特点的系统开发咨询服务，构建满足不同用能需求，标准化的网络平台。
    </p>
    <h2>②流程</h2>
    <p class="content">
      【需求分析】目标定位→【平台规划】内容策划→【项目开发】界面设计→【测试验收】项目人员测试
    <div style=" width:100%;text-align: center"><img src="../../images/综合服务平台开发流程图.jpg" alt="" style="width: auto;"></div>
    </p>
    <h2>③案例</h2>
    <div class="content">
      <img style="display: inline-block; width: 600px; height: 320px"  src="../../images/智慧能效平台.png" alt="">
      <img style="display: inline-block; width: 600px; height: 320px" src="../../images/电机评价平台.png" alt="">
      <div style=" width:100%;text-align: center"><img style="width: 800px; text-align: center;margin-bottom: 45px" src="../../images/标准直通车.png" alt=""></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ServiceDevelopmentPlatform"
}
</script>

<style scoped>
#main{
  width: 1200px;
  margin: 110px  auto 0 auto ;
}
h2{
  margin-top: 40px;
}
.content{
  margin-top: 20px;
  font-size: 20px;
}
.content img{
  width: 100%;
  height: 100%;
}
</style>