<template>
  <div id="main">

    <el-card style="margin: 20px 0">
      <div slot="header" class="clearfix">
        <span style="font-size: 20px; font-weight: 700">服务内容</span>
      </div>
      <div>
        按照企业实际需求，指导企业参与相关的标准培训，有计划的制订“企业化”标准及标准化的培训活动。
      </div>
    </el-card>

    <div class="search-box">
      <el-input  style="width: 500px" v-model="searchValue" placeholder="请输培训名称或关键词"></el-input> <el-button type="danger">检索</el-button>
    </div>

    <div class="classification-box">
      培训分类：
      <el-divider></el-divider>

      <el-table
          :data="tableData"
          border
          style="width: 100%;">
        <el-table-column
            prop="date"
            label="综合排序"
            width="110"
        >
        </el-table-column>
        <el-table-column
            prop="name"
            label="近一个月"
            width="110">
        </el-table-column>
        <el-table-column
            prop="address"
            label="更新时间"
            sortable
            width="110">
        </el-table-column>
        <el-table-column
            prop="address"
            label="开课时间"
            sortable
            width="110">
        </el-table-column>
        <el-table-column
            prop="address"
            label="价格"
            sortable
            width="110">
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="scope">
           <p style="text-align: right; margin-right: 40px">为您找到相关结果 <span style="color: #F56C6C">{{searchSize}}</span> 条记录</p>
          </template>
        </el-table-column>
      </el-table>
    </div>

<!--    <el-tabs v-model="activeName" @tab-click="handleClick">-->
<!--      <el-tab-pane label="标准培训" name="first">-->
<!--        <component :is="Train"/>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="标准在线培训" name="second">-->
<!--        <component :is="OnlineTraining"/>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="培训计划" name="third">-->
<!--        <component :is="TrainingPlan"/>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="培训中" name="fourth">-->
<!--        <component :is="InTraining"/>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="培训历史" name="fifth">-->
<!--        <component :is="TrainingHistory"/>-->
<!--      </el-tab-pane>-->
<!--    </el-tabs>-->
  </div>
</template>

<script>
import Train from "@/View/module/Train";
import OnlineTraining from "@/View/module/OnlineTraining";
import TrainingPlan from "@/View/module/TrainingPlan";
import InTraining from "@/View/module/InTraining";
import TrainingHistory from "@/View/module/TrainingHistory";
export default {
  name: "StandardizedTraining",
  data(){
    return{
      activeName:'first',
      searchValue:'',
      searchSize: 182,
      Train,
      OnlineTraining,
      TrainingPlan,
      InTraining,
      TrainingHistory
    }
  }
}
</script>

<style scoped>
#main{
  width: 1200px;
  margin: 110px auto 0 auto;
}
.search-box{
  margin: 10px 0;
  width: 600px;
}
.classification-box{
  margin: 45px 0;
}
/deep/.el-table th > .cell {
  text-align: center;
}

/deep/.el-table .cell {
  text-align: center;
}
</style>