<template>
  <div id="component">
    <div class="module1"><span class="VerticalLine"></span><span class="content">进行中的培训</span>
      <el-divider></el-divider>
      <div class="module-content">
        当前暂无 进行中 培训
      </div>
    </div>
    <div class="module2"><span class="VerticalLine"></span><span class="content">报名中 的培训</span>
      <el-divider></el-divider>
      <div class="module-content">
        当前暂无 报名中 培训
      </div>
    </div>
    <div class="module3"><span class="VerticalLine"></span><span class="content">计划中 的培训</span>
      <el-divider></el-divider>
      <div class="module-content">
        当前暂无 计划中 培训
      </div>
    </div>
    <div class="module4"><span class="VerticalLine"></span><span class="content">已完成 的培训</span>
      <el-divider></el-divider>
      <div class="module-content" style="margin-bottom: 20px">
        <ul>
          <li>
            <img src="../../images/默认课.png" alt="">
            <div class="curtain">
              <p class="head">标准化法宣惯第12期</p>
              <p class="label">公益培训</p>
              <el-tag type="warning">来看</el-tag>
            </div>
          </li>
          <li>
            <img src="../../images/默认课.png" alt="">
            <div class="curtain">
              <p class="head">标准化法宣惯第11期</p>
              <p class="label">公益培训</p>
              <el-tag type="warning">查看</el-tag>
            </div>
          </li>
          <li>
            <img src="../../images/默认课.png" alt="">
            <div class="curtain">
              <p class="head">标准化法宣惯第10期</p>
              <p class="label">公益培训</p>
              <el-tag type="warning">查看</el-tag>
            </div>
          </li>
          <li>
            <img src="../../images/默认课.png" alt="">
            <div class="curtain">
              <p class="head">标准化法宣惯第09期</p>
              <p class="label">公益培训</p>
              <el-tag type="warning">查看</el-tag>
            </div>
          </li>
          <li>
            <img src="../../images/默认课.png" alt="">
            <div class="curtain">
              <p class="head">标准化法宣惯第08期</p>
              <p class="label">公益培训</p>
              <el-tag type="warning">查看</el-tag>
            </div>
          </li>
          <li>
            <img src="../../images/默认课.png" alt="">
            <div class="curtain">
              <p class="head">标准化法宣惯第07期</p>
              <p class="label">公益培训</p>
              <el-tag type="warning">查看</el-tag>
            </div>
          </li>

        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Train"
}
</script>

<style scoped>
#component{
  margin-top: 20px;
}
.module1,.module2,.module3,.module4{
  margin-top: 60px;

}
.module1{
  color: #F56C6C;
}
.module2{
  color: #409EFF;
}
.module3{
  color: #67C23A;
}
.module4{
  color: #E6A23C;
}
.module-content{
  margin-top: 10px;
  text-align: center;
}
.content {
  display: inline-block;
  vertical-align: top;
}

.VerticalLine {
  display: inline-block;
  width: 3px;
  height: 20px;
  border-radius: 5px;
  margin-right: 10px;
  vertical-align: center;
}
.module1 .VerticalLine{
  background-color: #F56C6C;
}
.module2 .VerticalLine{
  background-color: #409EFF;
}
.module3 .VerticalLine{
  background-color: #67C23A;
}
.module4 .VerticalLine{
  background-color: #E6A23C;
}

.module-content li{
  position: relative;
  display: inline-block;
  width: 14%;
  height: 250px;
  margin-right: 2%;
  vertical-align: top;
  overflow: hidden;

}
.module-content li img{
  width: 100%;
  height: 100%;
}
.module-content li :hover{
  transform: scale(1.1);
}
.curtain{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  padding: 20px;
  color: #fff;
  cursor: pointer;
  z-index: 99;
  transition: all 0.6s;
}
.curtain .label{
  text-align: left;
  margin-top: 20px;
}
.curtain .el-tag{
  margin-top: 60px;
  background-color: #E6A23C;
  color: #fff;
}

.el-divider {
  margin: 0;
}

</style>