<template>
  <div id="main">
    <h2>①服务内容</h2>
    <p class="content">
      助力企业发展，助推优质企业成为行业领跑企业的标准化咨询服务。协助企业获得“领跑者”标识，成为行业领跑企业。主要工作是编制、发布领跑者评价标准，进行全国“领跑者”评选。政府主管部门为：国家市场监督管理局。
    </p>
    <h2>②流程</h2>
    <p class="content" style="text-align: center">
      <img style="width: 600px" src="../../images/领跑者流程图.png" alt="">
    </p>
    <h2>③案例</h2>
    <div class="content" style="margin: 0 auto">
      <ul>
        <li ><img src="../../images/华菱安赛乐米塔尔汽车板有限公司.png" alt="" ></li>
      </ul>
    </div>
    <h2 style="text-align: center">企业标准“领跑者”标准化托管服务</h2>
  </div>
</template>

<script>
export default {
  name: "Leader"
}
</script>

<style scoped>

#main{
  width: 1200px;
  margin: 110px auto 45px auto;
}

h2{
  margin-top: 40px;
}
.content{
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
}
ul{
  text-align: center;
}
ul li{
  display: inline-block;
  width: 250px;
  margin:0 20px 0 20px;
}
ul li img{
  width: 100%;
}

</style>