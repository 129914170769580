import { render, staticRenderFns } from "./StandardizedTraining.vue?vue&type=template&id=c6faa542&scoped=true&"
import script from "./StandardizedTraining.vue?vue&type=script&lang=js&"
export * from "./StandardizedTraining.vue?vue&type=script&lang=js&"
import style0 from "./StandardizedTraining.vue?vue&type=style&index=0&id=c6faa542&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6faa542",
  null
  
)

export default component.exports