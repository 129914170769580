<template>
  <div id="main">
    <h2>①服务内容</h2>
    <p class="content" style="text-align: left">
      指导客户建立企业标准化体系，有针对性的主导或参与国家标准、团体标准等各级各类标准的制修订计划；鼓励有条件的企业参与国际标准的制修订计划。
    </p>
    <h2>②流程</h2>
    <p class="content">
      <img src="../../images/标准化制修订流程图.png" alt="">
    </p>
    <h2>③案例</h2>
    <div class="content" style="margin: 0 auto">
      <ul>
        <li style="background-color: #E9E9EB"><img src="../../images/湖南普菲克生物科技有限公司.png" alt=""></li>
        <li ><img src="../../images/仁天环保.jpg" alt="" ></li>
      </ul>

    </div>
    <h2 style="text-align: center">标准研制托管</h2>
  </div>
</template>

<script>
export default {
  name: "StandardizedHosting",
  data(){
    return{
      activeName:'first',
    }
  }
}
</script>

<style scoped>
#main{
  width: 1200px;
  margin: 110px auto;
}
h2{
  margin-top: 40px;
}
.content{
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
}
ul{
  text-align: center;
}
ul li{
  display: inline-block;
  width: 250px;
  margin:0 20px 0 20px;
}
ul li img{
  width: 100%;
}

</style>